import { RefObject, useEffect, useState } from 'react';
import { MenuOpenDirection } from '../../VerticalMenu.types';
import { TestIds } from '../constants';
import { debounce, isBrowser } from '../../../../core/commons/utils';

const isRequiredSpaceForSubMenuExceedsAllowedHeight = (
  menuOpenDirection: MenuOpenDirection,
  menuItemHeight: number,
) => {
  return (subMenuElem: Element) => {
    const subMenuElemPosition = subMenuElem.getBoundingClientRect();

    // We are checking whether there is enough space to open the menu on Bottom.
    // It means that we need to consider the menu current open direction.
    // Current Direction = Bottom => Simply add top position of submenu to submenu height
    // Current Direction = Top => In addition to the prior calculation,
    // add another time the submenu height and decrease the height of one submenu item
    const requiredSpaceForBottomSubMenu =
      menuOpenDirection === 'top'
        ? subMenuElemPosition.top +
          subMenuElemPosition.height +
          subMenuElemPosition.height -
          menuItemHeight
        : subMenuElemPosition.top + subMenuElemPosition.height;
    return (
      requiredSpaceForBottomSubMenu > document.documentElement.clientHeight
    );
  };
};

export default function useMenuOpenDirection(
  navRef: RefObject<HTMLElement>,
  menuItemHeight: number,
): MenuOpenDirection {
  const [menuOpenDirection, setMenuOpenDirection] = useState<MenuOpenDirection>(
    'bottom',
  );

  const updateMenuOpenDirection = () => {
    setMenuOpenDirection(currentMenuOpenDirection => {
      if (!navRef.current) {
        return currentMenuOpenDirection;
      }

      const subMenus = navRef.current.querySelectorAll(
        `[id^=${TestIds.subMenuPrefix}]`,
      );

      const notEnoughHeightForBottomMenu = Array.from(subMenus).some(
        isRequiredSpaceForSubMenuExceedsAllowedHeight(
          currentMenuOpenDirection,
          menuItemHeight,
        ),
      );

      if (
        currentMenuOpenDirection === 'bottom' &&
        notEnoughHeightForBottomMenu
      ) {
        return 'top';
      } else if (
        currentMenuOpenDirection === 'top' &&
        !notEnoughHeightForBottomMenu
      ) {
        return 'bottom';
      } else {
        return currentMenuOpenDirection;
      }
    });
  };

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    const debouncedHandler = debounce(updateMenuOpenDirection, 300);

    window.addEventListener('resize', debouncedHandler);
    updateMenuOpenDirection();
    return () => window.removeEventListener('resize', debouncedHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return menuOpenDirection;
}
